<template>
  <div class="inner-section a11-wrapper">
    <breadcumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">মৃত্তিকার বিশ্লেষণপূর্বক প্রয়োজনীয় সার প্রয়োগ সুপারিশ সংক্রান্ত পরামর্শ প্রদান</h4>
      </template>
      <template v-slot:body>
        <div class="apps-header">
          <p>স্মারক নং: ২১২৫৬৫</p>
          <p>তারিখ: ২১/১২/২০২২</p>
        </div>
        <div class="apps-subject-wrapper mb-4">
          <p>পরিচালক</p>
          <p>বাংলাদেশ চা গবেষণা ইনস্টিটিউট</p>
          <p>শ্রীমঙ্গল-৩২১০</p>
          <p>জেলা- মৌলভীবাজার</p>
        </div>
        <p class="mb-4">বিষয়: <span class="dynamic-line-wrapper"></span> এর জন্য আবেদন।</p>
        <div class="cover-letter-wrapper">
          <p class="mb-2">প্রিয় মহোদয়,</p>
          <p style="line-height: 2rem;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;উপর্যুক্ত বিষয়ে, <span class="dynamic-line-wrapper"></span> চা বাগানের <span class="dynamic-line-wrapper"></span> নং অপরিনত সেকশনের <span class="dynamic-line-wrapper"></span> টি / <span class="dynamic-line-wrapper"></span> নং পরিনত সেকশনের <span class="dynamic-line-wrapper"></span> টি / নার্সারীর <span class="dynamic-line-wrapper"></span> টি / পুনঃআবাদি <span class="dynamic-line-wrapper"></span> নং সেকশনের <span class="dynamic-line-wrapper"></span> টি / পুনর্বাসনের জন্য <span class="dynamic-line-wrapper"></span> নং সেকশনের <span class="dynamic-line-wrapper"></span> টি / সম্প্রসারনের জন্য <span class="dynamic-line-wrapper"></span> নং সেকশনের <span class="dynamic-line-wrapper"></span> টি / বীজবাড়ির জন্য <span class="dynamic-line-wrapper"></span> টি করে ইনফিলিং এর জন্য <span class="dynamic-line-wrapper"></span> টি করে মাটির পুষ্টি উপাদান নির্ণয়ের জন্য <span class="dynamic-line-wrapper"></span> টি করে মোট <span class="dynamic-line-wrapper"></span> টি মৃত্তিকা নমুনা (ভিতরে ট্যাগ/লেবেল সহ) বিশ্লেষণপূর্বক প্রয়োজনীয় সার প্রয়োগ সুপারিশ ও পরামর্শ প্রদানের জন্য প্রেরণ করা হলো। প্রয়োজনীয় ব্যবস্থা গ্রহনের জন্য আপনাকে বিনীত অনুরোধ জানাচ্ছি।</p>
        </div>
        <div class="apps-footer">
          <p>ধন্যবাদান্তে</p>
          <p>আপনার বিশ্বস্ত,</p>
        </div>
      </template>
    </body-card>
  </div>
</template>
<script>
export default {

}
</script>
